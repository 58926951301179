import styled from "@emotion/styled";
import { colors, SpaceScale, FontScale } from "~/theme";

const StyledList = styled.ol`
  padding: 0;
  margin: 0;
  counter-reset: count;
  max-width: 700px;

  > li {
    counter-increment: count;
    color: ${colors.wine};
    display: block;
    position: relative;
    padding-left: ${SpaceScale(6)};

    &:before {
      content: counter(count);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      vertical-align: bottom;
      text-align: center;
      width: 24px;
      height: 24px;
      color: ${colors.wine};
      font-size: ${FontScale(4)};
      border: 2px solid ${colors.wine};
      position: absolute;
      left: 0;
      top: 12px;
    }
  }
`;

export default StyledList;
