import React, { FC } from "react";
import styled from "@emotion/styled";
import { FontScale, breakpoints } from "~/theme";

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem 0;
  font-size: ${FontScale(5.5)};

  ${breakpoints.tablet} {
    font-size: ${FontScale(6)};
  }
`;

const ListItem: FC = ({ children }) => {
  return <StyledListItem>{children}</StyledListItem>;
};

export default ListItem;
