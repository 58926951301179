import React, { FC } from "react";
import styled from "@emotion/styled";
import { colors, fonts, FontScale, SpaceScale, breakpoints } from "~/theme";
import Button from "../Button";
import SectionTitle from "../SectionTitle/SectionTitle";

const Section = styled.section`
  color: ${colors.white};
  background-color: ${colors.wine};
  padding: ${SpaceScale(3)};
  font-size: ${FontScale(3.5)};
  font-family: ${fonts.sans};
  font-weight: 400;
  padding: ${SpaceScale(4)};
  padding-bottom: ${SpaceScale(7)};

  ${breakpoints.tablet} {
    padding: ${SpaceScale(8.5)};
  }
`;

const SubTitle = styled.h2`
  margin: 0;
  margin-bottom: ${SpaceScale(5)};

  font-weight: 400;
  font-family: ${fonts.sans};
  font-size: ${FontScale(6)};

  ${breakpoints.tablet} {
    margin-bottom: ${SpaceScale(7)};
    font-size: 44px;
  }
`;

const Who = styled.section`
  margin-bottom: ${SpaceScale(6)};
  font-size: 18px;

  h3 {
    margin: 3px 0;
  }
  a {
    margin: 0;
    border-bottom: 1px solid ${colors.pink};
    text-decoration: none;
    color: inherit;
  }
`;

const About = styled.section`
  margin-bottom: ${SpaceScale(6)};
  max-width: ${SpaceScale(12.25)};
  font-size: ${FontScale(4.25)};
  line-height: ${FontScale(4.85, null)};

  ${breakpoints.tablet} {
    margin-bottom: ${SpaceScale(8.5)};
  }
`;

const Bold = styled.h3`
  margin: 0;
  margin-top: ${SpaceScale(5)};
`;

const Copy = styled.p`
  margin: 5px 0 0 0;
`;

type Props = any;
const FeaturedColab: FC<Props> = () => {
  return (
    <Section>
      <SectionTitle style={{ marginTop: "2rem" }}>
        Honor Honey honors,
      </SectionTitle>
      <SubTitle>Lakeview Pantry</SubTitle>
      <Who>
        <h3>This month’s pay-it-forward proceeds go to</h3>
        <a href="https://secure.qgiv.com/event/lakpanp2p/account/1019129/">Lakeview Pantry</a>
      </Who>
      <About>
        <Bold>Who are they?</Bold>
        <Copy>
          For over 50 years, Lakeview Pantry has been one of Chicago’s largest
          food pantries. Their mission is to eliminate hunger and poverty in our
          Chicago communities by providing food to fill the basic need of hungry
          people. Lakeview pantry empowers independence through social service
          programming and raises awareness of hunger and poverty.
        </Copy>
        <Bold>Why we love them</Bold>
        <Copy>
          Honor Our Neighbors.  This is one of our mantras; something you might
          find on the bottom of a jar. Lakeview Pantry does this by honoring the
          food produced to feed the less privileged. In addition to our
          pay-it-forward proceeds, we will also be donating some Honor Honey,
          allowing their clients to enjoy some Sweetness for Good.
        </Copy>
      </About>
      <Button href="https://secure.qgiv.com/event/lakpanp2p/account/1019129/">Donate</Button>
    </Section>
  );
};

export default FeaturedColab;
