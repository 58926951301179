import React, { FC } from "react";
import styled from "@emotion/styled";
import { colors, FontScale, SpaceScale, breakpoints, fonts } from "~/theme";

const Section = styled.section`
  display: flex;
  background-color: ${colors.honey};
  flex-direction: column;
  padding: ${SpaceScale(4)};

  /* Tablet screens and up  */
  ${breakpoints.tablet} {
    padding: ${SpaceScale(8)} ${SpaceScale(8)} 0;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 0 ${SpaceScale(4)};
  margin: 0 auto;
`;

const Quote = styled.h2`
  color: ${colors.pink};
  font-size: ${FontScale(5)};
  font-weight: 400;
  font-family: ${fonts.serif};

  ${breakpoints.mobile} {
    font-size: ${FontScale(5)};
    max-width: 250px;
  }

  ${breakpoints.tablet} {
    margin-bottom: ${SpaceScale(9)};
    font-size: ${FontScale(6.5)};
  }
`;

const Story = styled.div`
  color: ${colors.wine};
`;

const Title = styled.h1`
  font-size: ${FontScale(6.5)};
  font-weight: 400;
  margin: 0 0 ${SpaceScale(4)} 0;

  ${breakpoints.tablet} {
    font-size: ${FontScale(8.5)};
  }
`;

const Copy = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${FontScale(4)};
  margin-bottom: ${SpaceScale(5)};
  flex-direction: column;

  ${breakpoints.tablet} {
    flex-direction: row;
    font-size: ${FontScale(5)};
    margin-bottom: ${SpaceScale(8.5)};
  }
`;

const Column = styled.div`
  width: 100%;

  ${breakpoints.tablet} {
    width: 45%;
  }

  p {
    font-size: ${FontScale(4.5)};
    line-height: ${FontScale(5.5, null)};
  }
  bold {
    font-weight: 600;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;

type Props = any;
const MyStory: FC<Props> = () => {
  return (
    <Section>
      <Inner>
        <Quote>On a mission to be like the bees.</Quote>
        <Story>
          <Title>Jon’s Story</Title>
          <Copy>
            <Column>
              <p>
                A few years ago, I looked at my neighborhood parkway—the area
                between the sidewalk and the street—and wondered, could this be
                more than just a home for flowers? I became inspired to make the
                parkway into a working, sustainable, highly productive organic
                garden—a 25’x5’ mini parkway farm, where I now grow organic
                vegetables every spring, summer, and fall. My family eats from
                the garden. Many of my neighbors do too.
              </p>
              <p>
                Gardening was my gateway to beekeeping. I connected with a local
                apiary to figure out how to create a rooftop bee hive.
              </p>
              <p>
                I take great pleasure in watching these wise little creatures
                buzz around the neighborhood, sipping water from bird baths,
                working hard on my tiny urban farm.
              </p>
              <p>
                Of course, they don’t stop there—they also produce a lot of
                honey. More honey than I ever could have imagined.
              </p>
            </Column>
            <Column>
              <p>
                Bee’s are the ultimate do-gooder’s: they honor life around them
                by pollinating the plants and they pay it forward by producing
                sweet, delicious honey.
              </p>
              <p>
                <Bold>
                  Honor Honey was born from the desire to act a little more like
                  the bees.
                </Bold>{" "}
                To step outside the traditional system of dollars & cents and
                act in accordance with the laws of nature. To give and take on
                the honor system—with an understanding that we’re all honest
                creatures, trying our best to live in cooperation with each
                other and the natural world.
              </p>
              <p>
                So take a jar of Honor Honey. And then pay it forward by
                donating whatever amount feels right to our charity of choice,
                to support local farms and agriculture.
              </p>
              <p>Spread sweetness! Share your honey!</p>
            </Column>
          </Copy>
        </Story>
      </Inner>
    </Section>
  );
};

export default MyStory;
