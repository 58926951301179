import styled from "@emotion/styled";
import { FontScale, SpaceScale, colors, breakpoints } from "../../theme";
import { css } from "@emotion/core";

type Props = { nature?: "ghost" };
const Button = styled.a<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: ${FontScale(4)};
  font-weight: 800;
  padding: 0 32px;
  height: ${SpaceScale(6.125)};

  color: ${colors.wine};
  background-color: ${colors.white};

  ${(p) =>
    p.nature === "ghost" &&
    css`
      transition: all 200ms ease;
      color: ${colors.white};
      background-color: transparent;
      border: 1.5px solid ${colors.white};

      &:hover {
        color: ${colors.pink};
        background-color: ${colors.white};
      }
    `}

  ${breakpoints.tablet} {
    width: ${SpaceScale(9)};
  }
`;

export default Button;
