import styled from "@emotion/styled";
import { colors, fonts, FontScale, breakpoints } from "~/theme";

const Title = styled.h2`
  font-family: ${fonts.serif};
  font-size: ${FontScale(5.5)};
  font-weight: 400;
  color: ${colors.pink};
  margin: 0;

  ${breakpoints.tablet} {
    font-size: 32px;
  }
`;

export default Title;
