import React, { FC } from "react";
import styled from "@emotion/styled";
import List, { ListItem } from "~/components/List";
import { colors, fonts, FontScale, SpaceScale, breakpoints } from "~/theme";
import Div100vh from "react-div-100vh";

const Underline = styled.span`
  text-decoration: underline;
  text-decoration-color: ${colors.pink};
`;

const Container = styled(Div100vh)`
  background-color: ${colors.honey};
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoints.tablet} {
    padding: 0 ${SpaceScale(8)};
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 0 ${SpaceScale(4)};
  margin: 0 auto;
`;

const Plus = styled.span`
  color: ${colors.pink};
  font-size: ${FontScale(5)};
  vertical-align: top;
`;

const Title = styled.h2`
  color: ${colors.pink};
  font-family: ${fonts.serif};
  font-size: ${FontScale(7)};
  font-weight: 400;
  max-width: 600px;
  margin-bottom: ${SpaceScale(6.5)};
`;

const Subtitle = styled.h3`
  color: ${colors.wine};
  font-size: ${FontScale(6)};
  font-weight: 600;
  margin: 0 0 0 ${SpaceScale(6)};
  padding: 0;
`;

const HonorSystem: FC = () => {
  return (
    <Container>
      <Inner>
        <Title>
          Local honey traded on the Honor System
          <Plus>+</Plus>
        </Title>
        <Subtitle>How it works:</Subtitle>
        <List>
          <ListItem>
            <Underline>Take</Underline> a jar of Honor Honey
          </ListItem>
          <ListItem>
            <Underline>Donate</Underline> to one of our favorite organizations
          </ListItem>
          <ListItem>
            Tag us <Underline>@honor.honey</Underline> and tell us what or whom
            you’re honoring.
          </ListItem>
        </List>
      </Inner>
    </Container>
  );
};

export default HonorSystem;
